$sofia: "sofia-pro";
$plex: "ibm-plex-sans";

@mixin h1_Sofia_Pro {
    font-size: 60px;
    line-height: 70px;
    font-family: $sofia;
    letter-spacing: -2.1px;
    font-weight: lighter;
}
@mixin h2_Sofia_Pro {
    font-size: 42px;
    line-height: 50px;
    font-family: $sofia;
    letter-spacing: -1.05px;
    font-weight: bold;
}
@mixin h3_Sofia_Pro {
    font-size: 32px;
    line-height: 40px;
    font-family: $sofia;
    letter-spacing: -0.64px;
    font-weight: bold;
}
@mixin h4_Sofia_Pro {
    font-size: 32px;
    line-height: 40px;
    font-family: $sofia;
    letter-spacing: -0.64px;
    font-weight: 300;
}
@mixin h5_Sofia_Pro {
    font-size: 24px;
    line-height: 32px;
    font-family: $sofia;
    letter-spacing: -0.36px;
    font-weight: bold;
}
@mixin h6_Sofia_Pro {
    font-size: 18px;
    line-height: 24px;
    font-family: $sofia;
    letter-spacing: -0.18px;
    font-weight: bold;
}
@mixin h7_Sofia_Pro {
    font-size: 14px;
    line-height: 20px;
    font-family: $sofia;
    letter-spacing: 0;
    font-weight: bold;
}
@mixin h8_Sofia_Pro {
    font-size: 14px;
    line-height: 18px;
    font-family: $sofia;
    letter-spacing: -0.14px;
    font-weight: 500;
}
@mixin h9_Sofia_Pro {
    font-size: 12px;
    line-height: 15px;
    font-family: $sofia;
    letter-spacing: 0;
    font-weight: bold;
}
@mixin h10_Sofia_Pro {
    font-size: 12px;
    line-height: 15px;
    font-family: $sofia;
    letter-spacing: -0.12px;
    font-weight: 500;
}
@mixin IBM_Plex {
    font-size: 16px;
    line-height: 20px;
    font-family: $plex;
    letter-spacing: -0.16px;
    font-weight: normal;
}
@mixin T2_IBM_Plex {
    font-size: 14px;
    line-height: 18px;
    font-family: $plex;
    letter-spacing: -0.14px;
    font-weight: normal;
}
@mixin T3_IBM_Plex {
    font-size: 12px;
    line-height: 15px;
    font-family: $plex;
    letter-spacing: -0.12px;
    font-weight: bold;
}
@mixin T4_IBM_Plex {
    font-size: 12px;
    font-family: $plex;
    letter-spacing: -0.12px;
    font-weight: normal;
}