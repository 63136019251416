@mixin btnWithoutImg($color, $backgroundColor, $hoverBackgroundColor) {
    //Create button and in button add <a></a> with text for example "Mehr sehen". On button parent include this mixin with paramaetrs "@include btnWithoutImg($main-color-00, $main-color-90, $main-color-80);"
    button {
        overflow: hidden;
        // display: flex;
        border: none;
        max-height: 40px;
        width: auto;
        height: 100%;
        background-color: transparent;
        a {
            background-color: $backgroundColor;
            max-height: 40px;
            height: 100%;
            border-radius: 50px;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: none;
            padding: 10px 20px;
            color: $color;
            font-family: $sofia;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
        a:hover {
            background-color: $hoverBackgroundColor;
            cursor: pointer;
            color: $color;
            text-decoration: none;
        }
    }
    // button:focus {
    //     outline: 3px solid #CDCDCD;
    // }
    
}
@mixin btnWithImg($color, $backgroundColor, $backgroundColorVisited) {
    .btn-cont {
        width: 100%;
        height: 91px;
    }
    .btn-cont:focus-within {
        // outline: 3px solid #CDCDCD;
        span {
            width: 87px;
            border-radius: 50% 0 0 50%;
        }
    }
    button {
        border-radius: 100px;
        border: 2px solid $color;
        overflow: hidden;
        color: $color;
        h4 {
            padding-left: 45px;
            top: 46%;
            transform: translateY(-50%);
            z-index: 1;
        }
        span {
            z-index: 0;
            top: 0;
            right: 0;
            width: 87px;
            border-radius: 50% 0 0 50%;
            background-color: $backgroundColor;
            transition: 0.5s;
        }
        p {
            margin: 0;
        }
        img {
            margin-right: 36px;
            margin-left: auto;
            width: 16px;
            height: 16px;
        }
    }
    button:hover {
        cursor: pointer;
        span {
            border-radius: 0;
            width: 100%;
        }
    }
    button:visited {
        span {
            background-color: $backgroundColorVisited;
            width: 100%;
        }
    }
    a {
        border-radius: 100px;
        border: 2px solid $color;
        display: block;
        overflow: hidden;
        color: $color;
        h4 {
            padding-left: 45px;
            top: 46%;
            transform: translateY(-50%);
            z-index: 1;
        }
        span {
            z-index: 0;
            top: 0;
            right: 0;
            width: 87px;
            border-radius: 50% 0 0 50%;
            background-color: $backgroundColor;
            transition: 0.5s;
        }
        p {
            margin: 0;
        }
        img {
            margin-right: 36px;
            margin-left: auto;
            width: 16px;
            height: 16px;
        }
    }
    a:hover {
        cursor: pointer;
        color: black;
        span {
            border-radius: 0;
            width: 100%;
        }
    }
    a:visited {
        span {
            background-color: $backgroundColorVisited;
            width: 100%;
        }
    }
}
@mixin btnWithImgLeft($color, $backgroundColor, $backgroundColorVisited) {
    .btn-cont {
        width: 100%;
        height: 91px;
    }
    .btn-cont:focus-within {
        // outline: 3px solid #CDCDCD;
        span {
            width: 87px;
            border-radius: 50% 0 0 50%;
        }
    }
    button {
        border-radius: 100px;
        border: 2px solid $color;
        overflow: hidden;
        color: $color;
        h4 {
            padding-left: 100px;
            top: 46%;
            transform: translateY(-50%);
            z-index: 1;
        }
        span {
            z-index: 0;
            top: 0;
            left: 0;
            width: 87px;
            border-radius: 0 50% 50% 0;
            background-color: $backgroundColor;
            transition: 0.5s;
        }
        p {
            margin: 0;
        }
        img {
            margin-left: 36px;
            margin-right: auto;
            width: 16px;
            height: 16px;
        }
    }
    button:hover {
        cursor: pointer;
        span {
            border-radius: 0;
            width: 100%;
        }
    }
    button:visited {
        span {
            background-color: $backgroundColorVisited;
            width: 100%;
        }
    }
}
@mixin btnWithStaticImg($mainColor, $hoverColor, $color) {
    .btn-cont {
        display: block;
        width: 215px;
    }
    // .btn-cont:focus-within {
    //     outline: 3px solid #CDCDCD;
    // }
    button {
        border-radius: 20px;
        border: none;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        align-items: center;
        background-color: $mainColor;
        font-family: $sofia;
        color: $color;
        padding: 10px 12px;
        font-weight: bold;
        width: auto;
        img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $main-color-90;
        }
    }
    button:hover {
        cursor: pointer;
        background-color: $hoverColor;
    }

}
@mixin  addBtn {
    background-color: transparent;
    border-radius: 0;
    border: none;
    img {
        height: 30px;
        width: 30px;
        background-color: $active-color-40;
        transform: rotate(180deg);
        border-radius: 50%;
        border: none;
        padding: 11px 8px;
    }
    img:hover {
        cursor: pointer;
        background-color: $active-color-60;
    }
}
@mixin input() {
    input {
        border: none;
    }
    input:focus {
        outline: none;
    }
    input:focus::placeholder {
        color: transparent;
    }
}
@mixin indexH2() {
    h2 {    
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.64px;
        text-align: center;
        text-transform: uppercase;
        font-family: $sofia;
        font-weight: 300 !important;
        margin: 100px 0 100px;
    }
    h2::after {
        content: "";
        width: 50px;
        height: 0px;
        border: 1px solid $main-color-90;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}