// Utf-8
@charset "utf-8";
@import "bootstrap/bootstrap"; // --- Vendors Bootstrap 4
@import "mixins";
@import "fonts";
@import "colors";

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(./font/Roboto-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(./font/Roboto-BlackItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./font/Roboto-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./font/Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./font/Roboto-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./font/Roboto-Bold.ttf) format('truetype');
}

// Utf-8
@charset "utf-8";
@import "bootstrap/bootstrap"; // --- Vendors Bootstrap 4
@import "mixins";
@import "fonts";
@import "colors";

* {
    margin: 0;
    padding: 0;
    transition: 0.6s;
}
// .row-padding {
//     margin: 0 13px;
// }
main {
    margin-top: 100px;
}
header {
    background-color: $main-color-00;
    position: fixed;
    top: 0;
    height: 102px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.6s;
    z-index: 3;
    .nav-cont {
        position: absolute;
        height: 100px;
        z-index: 4;
    }
    .bordless {
        border-bottom: none;
    }
    nav {
        height: 40px;
        margin: 30px 28px;
        background-color: white;
        z-index: 3;
        // a {
        //     height: 100%;
        //     overflow: hidden;
        //     color: black;
        //     text-decoration: none;
        // }
        // a:hover {
        //     text-decoration: underline;
        //     color: black;
        //     cursor: pointer;
        // }
        .nav-logo {
            height: 40px;
            z-index: 3;
        }
    }

    .fluid-height {
        height: 100%;
        width: auto;
        object-fit: contain;
    }
    .search-cont {
        position: absolute;
        height: 40px;
        z-index: 4;
        top: 30px;
        right: 115px;
        width: 288px;
        transition-delay: 100ms;
        form {
            position: absolute;
            height: 40px;
            width: 250px;
            transition: 0.6s;
            right: 0;
            padding: 0 5px;
            margin-left: 38px;
            border-radius: 50px;
            border: 2px solid $main-color-90;
            overflow: hidden;
            @include input;
            input {
                font-size: 14px;
                width: 100%;
                line-height: 20px;
                letter-spacing: -0.14px;
            }
            button {
                padding: 0 4px;
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                img {
                    object-fit: contain;
                    height: 24px;
                    width: 24px;
                }
            }
            button:hover {
                cursor: pointer;
            }
            button:focus {
                outline: none;
            }
        }
        form:focus-within, form:hover {
            width: 288px;
            margin-left: 0px;
        }
    }
    .nav-buttons {
        margin-left: auto;
        margin-right: 0;
        a {
            height: 40px;
            width: 40px;
            margin: 0 0 0 4px;
            overflow: visible;
            img {
                transition: 0.6s;
            }
            .front {
                opacity: 1;
            }
            .back {
                opacity: 0;
            }
        }
        // a:focus {
        //     img {
        //         outline: 3px solid #cdcdcd;
        //     }
        // }
        a:hover {
            .front {
                opacity: 0;
            }
            .back {
                opacity: 1;
            }
        }
        button {
            height: 40px;
            width: 40px;
            margin: 0 0 0 4px;
            overflow: visible;
            background-color: transparent;
            border: none;
            img {
                top: 0;
                left: 0;
                transition: 0.6s;
            }
            .back {
                opacity: 0;
            }
            .front {
                opacity: 1;
            }
        }
        button:hover {
            .back {
                opacity: 1;
            }
            .front {
                opacity: 0;
            }
        }
        // button:focus {
        //     outline: 3px solid #CDCDCD;
        // }
        .product-amount {
            background-color: $main-color-90;
            font-size: 10px;
            bottom: -2px;
            z-index: 1;
            right: 0;
            color: $main-color-00;
            width: 17px;
            height: 17px;
        }
    }
    .navbar-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 2px solid $main-color-90;
        border-radius: 50%;
        z-index: 3;
        transition: 0.6s;
        &:focus {
            box-shadow: none;
        }
    }
    .navbar-toggler.rotate {
        span {
            transform: rotate(90deg);
        }
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
    .nav-links {
        text-transform: uppercase;
        text-decoration: none;
        position: absolute;
        font-size: 14px;
        height: auto;
        border-bottom: 2px solid black;
        transition: 0.6s;
        font-weight: bold;
        top: 102px;
        transform: translateY(-100%);
        padding: 32px 18px 12px 18px;
        line-height: 20px;
        a {
            margin: 0 5px;
            color: black;
            text-decoration: none;
            line-height: 30px;
            padding: 0 10px;
            font-weight: 500;
            font-family: $sofia;
            &:hover {
                text-decoration: none;
                background-color: $active-color-60;
                border-radius: 100px;
            }
        }
        .current {
            background-color: $active-color-40;
            border-radius: 100px;
        }
        .dropdown-toggle {
            font-size: 14px;
            margin: 0 5px;
            font-family: $sofia;
            text-transform: uppercase;
            color: black;
            font-weight: 500;
            border: none;
            border-radius: 100px;
            padding: 0 10px;
            line-height: 30px;
            &::after {
                content: none;
            }
            &:hover {
                text-decoration: none;
                background-color: $active-color-60;
            }
        }
        .dropdown-menu {
            padding: 0;
            top: 38px !important;
            transition: 0.6s;
            border: 2px solid black;
            overflow: hidden;
            a {
                margin: 0;
                border-radius: 0;
                padding: 0 15px;
                font-size: 12px;
                font-weight: 500;
            }
            li {
                padding: 4px 0;
            }
        }
        .dropdown-menu.show {
            top: 38px !important;
            transform: none !important;
        }
    }
    .nav-links.active {
        padding: 2px 18px 12px 18px;
        transform: translateY(0%);
    }
    .nav-links.hide {
        .dropdown {
            width: 100%;
        }
        a, .dropdown-toggle {
            width: 100%;
            margin: 2px 0;
            text-align: left;
        }
    }
}


.language-modal {
    .lang-diag {
        margin-top: 30vh;
    }
    .modal-head {
        border-bottom: none;
        h5 {
            @include h5_Sofia_Pro;
            border-bottom: 2px solid $main-color-90;
        }
    }
    a {
        font-family: $sofia;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: uppercase;
        text-decoration: none;
        color: $main-color-90;
        font-weight: 900;
        img {
            padding-right: 5px;
        }
    }
    a:hover {
        color: $main-color-90;
    }
    button {
        top: 20px;
        right: 20px;
    }
}
.comparison-bar {
    z-index: 4;
    height: 70px;
    bottom: 0;
    transform: translateY(100%);
    transition: 0.5s;
    p {
        font-weight: bold;
    }
    a {
        margin-right: 5px;
        color: white;
        background-color: black;
        border: 1px solid black;
        text-decoration: none;
        padding: 5.5px 10px;
        &:hover {
            color: black;
            background-color: white;
        }
    }
    button {
        border: 1px solid black;
        background-color: black;
        padding: 4px 10px;
        color:  white;
    }
    .close-btn {
        background-color: white;
        color: black;
        &:hover {
            color: white;
            background-color: black;
            cursor: pointer;
        }
    }
}
.comparison-bar.active {
    transform: translateY(0);
}
header.scroll {
    top: -80px;
}
.scroll-container {
    height: calc(100vh - 100px);
    overflow: hidden;
    .carousel-container {
        overflow: hidden;
        height: calc(100vh - 100px);
        .slick-item {
            height: calc(100vh - 100px);
            .text-carousel {
                @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
                z-index: 0;
                background-color: $main-color-05;
                border-radius: 0 100px 0 0;
                bottom: 0;
                right: 69px;
                padding-left: 0 !important;
                padding-bottom: 30px !important;
                width: 640px;
                h1, h2 {
                    @include h2_Sofia_Pro;
                }
                h4 {
                    @include h4_Sofia_Pro;
                }
                button {
                    margin-top: 20px;
                    transform: translate(0, 0);
                }
                // button:focus {
                //     outline: 3px solid #cdcdcd;
                // }
                .slider {
                    width: 90%;
                    margin: auto;
                }
            }
            img {
                min-height: 600px;
                height: calc(100vh - 100px);
                object-fit: cover;
            }
        }
        .slick-dots {
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            bottom: 28px;
            right: 360px;
            li {
                list-style-type: none;
                border: 2px solid $main-color-90;
                border-radius: 50%;
                background-color: $main-color-05;
                width: 10px;
                height: 10px;
                margin-right: 5px;
                button {
                    display: none;
                }
            }
            li.slick-active {
                background-color: $main-color-90;
            }
            li:hover {
                cursor: pointer;
            }
        }
        button {
            border: none;
            background-color: transparent;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }
        .rss-next {
            right: 28px;
        }
        .rss-prev {
            left: 28px;
        }
        .rss-next:hover,
        .rss-prev:hover {
            cursor: pointer;
        }
        // button:focus {
        //     outline: 3px solid #cdcdcd;
        // }
        .sign {
            height: 60px;
            width: 30px;
            border: 2px solid $main-color-90;
            border-radius: 50px;
            background-color: transparent;
            transition: 0.6s;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            animation-name: arrowMove;
            animation-duration: 10s;
            animation-iteration-count: infinite;
        }
        .sign:hover {
            cursor: pointer;
        }
        .sign::after {
            content: "";
            width: 0;
            position: absolute;
            border: 1px solid $main-color-90;
            height: 25px;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.6s;
        }
    }
    .carousel-container.scroll {
        top: 20px;
    }
    .slick-list {
        overflow: visible;
    }
}
.main-product {
    @include indexH2;
    transition: 0.6s;
    .box {
        height: 550px;
        overflow: hidden;
    }
    .box-text {
        border-radius: 0px 100px 0px 0px;
        background-color: $active-color-40;
        height: 154px;
        bottom: 0;
        left: -80px;
        width: 430px;
        a {
            font-size: 18px;
            font-family: $sofia;
            font-weight: bold;
            letter-spacing: -0.18px;
            line-height: 24px;
            color: $main-color-90;
            text-decoration: none;
        }
    }
    .box-text-v2 {
        background-color: $main-color-05;
    }
    .box-img {
        img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }
        // img:focus {
        //     outline: 3px solid #cdcdcd;
        // }
        p {
            font-size: 18px;
            letter-spacing: -0.18px;
            line-height: 24px;
        }
        h2 {
            font-size: 18px;
            letter-spacing: -0.18px;
            line-height: 24px;
            text-align: left;
            font-weight: normal !important;
        }
    }
    .img-row {
        height: 300px;
    }
    .main-col {
        overflow: hidden;
        .main-link {
            z-index: 3;
        }
    }
    .main-col:hover {
        cursor: pointer;
        .box {
            transform: scale(1.08);
        }
        .box-text {
            left: 0;
        }
    }
}
.why-ewifoam {
    max-height: 80vh;
    margin-top: 150px;
    .text-col {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        background-color: $main-color-05;
        padding: 3%;
        label {
            margin: 0 10px 0 0;
            position: relative;
            display: flex;
            align-content: center;
            overflow: hidden;
            input {
                position: absolute;
                border: none;
                left: 42px;
                width: 77%;
                top: 50%;
                transform: translateY(-53%);
                background-color: $main-color-05;
            }
            input:focus {
                outline: none;
            }
            input:focus::placeholder {
                color: transparent;
                background-color: $main-color-05;
            }
        }
        h3 {
            font-family: $sofia;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: -0.64px;
            line-height: 38px;
            text-align: left;
            margin-bottom: 0p;
        }
        p {
            font-size: 16px;
            letter-spacing: -0.16px;
            line-height: 24px;
            font-family: $plex;
            margin-bottom: 5px;
        }
        span {
            font-size: 18px;
            letter-spacing: -0.18px;
            line-height: 24px;
            font-weight: bold;
            font-family: $sofia;
        }
        ul {
            font-size: 14px;
            font-family: $plex;
            letter-spacing: -0.14px;
            line-height: 22px;
            padding-left: 17px;
            margin-bottom: 0;
            li {
                width: 100%;
                font-family: $plex;

            }
        }
        button {
            margin-left: 0;
            margin-right: auto;
        }
        a {
            text-decoration: none;
        }
        a:hover {
            img {
                transform: scale(1.08);
            }
        }
    }
    .img-col {
        padding: 3% 0;
        overflow: hidden;
        .box-img img {
            max-height: 700px;
        }
    }
}
.row-product {
    @include indexH2;
    .rss-next, .rss-prev {
        transform: translateY(-50%);
        position: absolute;
        z-index: 1;
        top: 60%;
        background-color: transparent;
        border: none;
    }
    .rss-next {
        right: 20px;
    }
    .rss-prev {
        left: 20px;
    }
    .rss-next:hover,
    .rss-prev:hover {
        cursor: pointer;
    }
    // button:focus {
    //     outline: 3px solid #cdcdcd;
    // }
    .slider {
        overflow: hidden;
        max-height: 470px;
        margin-bottom: 30px;
        .slick-list {
            .slick-track {
                width: 100%;
                .product-box {
                    min-width: 318px;
                }
            }
        }
    }
    span {
        letter-spacing: 0;
        font-weight: bold;
    }
    p {
        letter-spacing: -0.14px;
        font-weight: normal;
    }
}
.answer {
    overflow: hidden;
    background-color: $main-color-05;
    @include btnWithImg($main-color-90, $active-color-40, $active-color-40);
    background-repeat: no-repeat;
    min-height: 500px;
    img {
        width: 24px;
        height: 24px;
    }
    h4 {
        @include h4_Sofia_Pro;
    }
    form {
        .answers-nav {
            ul {
                list-style-type: none;
                display: flex;
                padding-left: 0;
                flex: nowrap;
                li {
                    button{
                        margin-right: 5px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        border-radius: 50%;
                        background-color: $main-color-00;
                        color: $main-color-40;
                        opacity: 0.5;
                    }
                    button:hover {
                        border: 2px solid $main-color-90;
                        color: $main-color-90;
                        opacity: 1;
                    }
                    .active {
                        background-color: $main-color-90;
                        color: $main-color-00;
                        opacity: 1;
                    }
                    .active:hover {
                        color: $main-color-00;
                    }
                }
            }
        }
    }
    .main-answer {
        position: absolute;
        left: 0;
        transform: translateX(-100%);
        p {
            font-size: 32px;
            font-weight: bold;
            font-family: $sofia;
            letter-spacing: -0.64px;
            line-height: 38px;
        }
    }
    .main-answer.abowe {
        position: absolute;
        transform: translateX(-100%);
    }
    .start-answer {
        left: 0;
        h3 {
            font-size: 32px;
            font-weight: bold;
            font-family: $sofia;
            letter-spacing: -0.64px;
            line-height: 38px;
        }
    }
    .start-answer.abowe {
        position: absolute;
        left: 100%;
    }
    .last {
        @include btnWithImgLeft($main-color-90, $active-color-40, $active-color-20);
    }
    .center {
        position: relative;
        transform: translateX(0);
    }
    .to-remove-cont {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
    }
}
.expert {
    padding-bottom: 50px;
    @include indexH2;
    h2 {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        margin: 80px 0;
        button {
            right: 8px;
            top: 0;
        }
    }
    .text-box {
        h3 {
            font-weight: bold;
            font-family: $sofia;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.18px;
            margin: 0;
            padding-bottom: 1rem;
        }
        .extra-small {
            font-size: 12px;
            letter-spacing: -0.12px;
            line-height: 18px;
            font-weight: normal;
            font-family: $plex;
        }
        .small {
            font-weight: 500;
            letter-spacing: -0.14px;
            font-size: 14px;
            line-height: 20px;
            font-family: $sofia;
        }
    }
    .box-img {
        height: 390px;
    }
    h4 {
        @include h4_Sofia_Pro;
    }
}
.expert-page {
    padding-left: 24px !important;
    padding-right: 24px !important;
    .bord {
        border-bottom: 2px solid $main-color-90;
    }
    .exp-col {
        padding: 25px 10px;
    }
    h1 {
        font-size: 32px;
        font-weight: bold;
        font-family: $sofia;
        letter-spacing: -0.64px;
        line-height: 38px;
        line-height: 40px;
    }
    h5 {
        font-size: 18px;
        font-family: $sofia;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: -0.18px;
    }
    p {
        font-size: 12px;
        padding-left: 0;
        letter-spacing: -0.12px;
        line-height: 18px;
        font-family: $plex;
    }
    .pages {
        button {
            background-color: transparent;
            width: 40px;
            height: 40px;
            overflow: hidden;
            p {
                border-radius: 50%;
                background-color: $main-color-05;
            }
        }
        button.left {
            transform: rotate(180deg);
        }
        button:hover {
            filter: invert(100%)
        }
        // button:focus {
        //     outline: 3px solid #cdcdcd;
        // }
        .current {
            border: 2px solid $main-color-90 !important;
        }
    }
}
.expert-articles {
    .btn-cont-x2 {
        position: fixed;
        left: 50px;
        top: 40%;
    }
    h1 {
        @include h2_Sofia_Pro;
    }
    h2 {
        @include h4_Sofia_Pro;
    }
    h3 {
        font-family: $sofia;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.36px;
        line-height: 32px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.16px;
        font-family: $plex;
    }
    .thiner {
        font-size: 14px;
        letter-spacing: -0.14px;
        line-height: 22px;
    }
    .cont {
        background-color: $main-color-05;
        h3 {
            @include h5_Sofia_Pro;
        }
        ul {
            font-family: $sofia;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.18px;
        }
    }
    .prev {
        @include btnWithoutImg($main-color-00, $main-color-90, $main-color-90);
    }
    .next {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
    }
}
.advices {
    h4 {
        @include h4_Sofia_Pro;
    }
    p {
        font-family: $plex;
        font-size: 14px;
        letter-spacing: -0.14px;
        line-height: 22px;
    }
}
.product-details {
    padding-bottom: 50px;
    .details-col {
        max-height: 1000px;
        margin-bottom: 50px;
        padding-right: 100px;
        h1 {
            @include h2_Sofia_Pro;
        }
        p {
            letter-spacing: -0.14px;
            line-height: 22px;
            font-size: 14px;
            font-family: $plex;
        }

        @include btnWithStaticImg($active-color-40, $active-color-60, $main-color-90);
        button {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0;
            img {
                transform: rotate(45deg);
            }
        }
        .checked {
            background-color: $main-color-05;
            img {
                transform: rotate(0);
                border: 0;
            }
        }
        .checked:hover {
            background-color: $main-color-10;
        }
        button[aria-expanded="true"] img {
            transform: rotate(0) !important;
        }
        .tog {
            left: 40px;
            top: 30%;
            transform: translateY(-50%);
            font-size: 14px;
            text-align: left;
            font-family: $sofia;
            border-bottom: none !important;
        }
        .hiden-cont {
            p {
                border-bottom: 2px solid $main-color-90;
                font-weight: bold;
                line-height: 20px;
                letter-spacing: 0;
                button {
                    @include addBtn();
                    a {
                        text-decoration: none;
                        color: black;
                    }
                }
                // button:focus-within {
                //     outline: 3px solid #cdcdcd;
                // }
                .rotate img {
                    transform: rotate(0);
                }
            }
            .tabela {
                font-family:  $sofia;
                .table {
                    th {
                        font-size: 12px;
                        line-height: 16px;
                    }
                    td {
                        @include h10_Sofia_Pro;
                    }
                    .tab-row {
                        height: 60px;
                        p {
                            text-decoration: none;
                            font-weight: normal;
                            border: none;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .tab-img {
                        width: 60px;
                        img {
                            height: 44px;
                            width: 44px;
                        }
                    }
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
            .additional-info {
                p {
                    font-family: $sofia;
                    font-size: 14px;
                    border-bottom: none;
                }
                .info-cont {
                    .left-col {
                        p {
                            font-weight: normal;
                        }
                    }
                }
            }
            .to-download {
                p {
                    border-bottom: none;
                    font-family: $sofia;
                    font-size: 14px;
                }
                .to-download-cont {
                    button {
                        background-color: transparent;
                        border: none;
                        img {
                            transform: rotate(0);
                            border: none;
                        }
                        a {
                            font-weight: 500;
                            font-size: 14px;
                            letter-spacing: -0.14px;
                            line-height: 20px;
                            color: black;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .details-col::-webkit-scrollbar {
        display: none;
    }
    .resp-d {
        display: none;
    }
    .img-col {
        height: calc(100vh - 100px);
        overflow: hidden;
        .slid {
            background-color: $main-color-05;
        }
        .gal {
            bottom: 50px;
        }
        .next {
            right: calc(50% - 100px);
        }
        .prev {
            left: calc(50% - 100px);
        }
        .slick-list {
            height: 100%;
            .slick-track {
                height: 100%;
                img {
                    object-fit: cover;
                }
            }
        }
        .indic {
            z-index: 3;
            height: 40px;
            width: 100px;
            left: calc(50% - 2.5px);
            transform: translateX(-50%);
            background-color: white;
            border-radius: 20px;
            color: black;
            p {
                margin-bottom: 0;
            }
        }
        .slide-chang {
            top: 95%;
            transform: translateY(-50%);
            border: none;
            opacity: 1;
        }
        .close {
            top: 50px;
            width: 90px!important;
            background-position: -8% 30%;
            /* right: 50px; */
            font-size: 14px;
            font-weight: 700;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: end;
            font-family: sofia-pro;
            line-height: 20px;
        }
        .blueimp-gallery {
            background-color: $main-color-00;
            padding: 10% !important;
            .slides .slide {
                img {
                    max-width: 80%;
                    object-fit: contain;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }
}
.about-product {
    padding-bottom: 50px;
    .about-col {
        h2 {
            font-size: 32px;
            font-weight: bold;
            letter-spacing: -0.64px;
            line-height: 38px;
            font-family: $sofia;
        }
        h3 {
            @include h4_Sofia_Pro;
            line-height: 38px;
        }
        p {
            font-size: 14px;
            font-family: $plex;
            letter-spacing: -0.14px;
            line-height: 22px;
        }
    }
    .about-img {
        margin-top: 50px;
    }
}
.contact {
    padding-bottom: 50px;
    h1,
    h2 {
        font-family: $sofia;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.64px;
        font-weight: bold;
    }
    .exp-info p{
        font-family: $plex;
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 18px;
    }
    span {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        font-family: $sofia;
    }
    h3 {
        @include h4_Sofia_Pro;
        font-weight: lighter;
    }
    .contact-data {
        a {
            font-weight: lighter;
            color: $active-color-40;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.64px;
            font-family: $sofia;
            text-decoration: underline;
        }
        a:hover {
            color: $active-color-40;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    form {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        h3 {
            font-family: $sofia;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: -0.64px;
            line-height: 38px;
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url("/assets/img/bird2.png");
            background-repeat: no-repeat;
            background-size: 12px 8px;
            background-position-x: 18px;
            background-position-y: 50%;
            font-size: 14px;
            font-family: $sofia;
            font-weight: 500;
            letter-spacing: -0.14px;
            line-height: 20px;
            padding: 11px 11px 11px 44px;
            border-radius: 20px;
            border: 2px solid $main-color-90;
            // option {
            //     border-radius: 20px;
            //     border: 2px solid $main-color-90;
            //     width: calc(100% - 40px);
            // }
        }
        select:hover {
            cursor: pointer;
        }
        input {
            font-size: 12px;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $main-color-90;
            letter-spacing: -0.14px;
            line-height: 22px;
            font-family: $plex;
        }
        input::placeholder {
            color: $main-color-40;
        }
        input:focus {
            outline: none;
        }
        textarea {
            border: 2px solid $main-color-90;
            border-radius: 10px;
            background-color: transparent;
            color: $main-color-90;
            padding: 10px;
            letter-spacing: -0.14px;
            line-height: 22px;
            font-family: $plex;
            font-size: 12px;
        }
        input[type="checkbox"] {
            margin-right: 5px;
        }

        input:checked {
            accent-color: $main-color-90;
        }
        p {
            font-size: 12px;
            font-family: $plex;
            letter-spacing: -0.12px;
            line-height: 18px;
            margin-bottom: 0;
        }
        .contact-alert {
            color: $error-normal;
            display: none;
        }
        .contact-alert.active {
            color: $error-normal;
            display: flex;
            align-items: center;
        }

        .input-red{
            border-color: $error-normal;
        }
        .error-info{
            span{
                color: $error-normal;
                font-size: 12px;
                font-family: ibm-plex-sans;
                letter-spacing: -.12px;
                line-height: 18px;
                font-weight: 400;
            }
        }
        // input[type="checkbox"].permission-red {
        //     margin-right: 5px;
        //     border-color: $error-normal;
        // }

        .checkbox-square {
            width: 18px;
            height: 18px;
            padding: 0;
            transition: none;
            background-color: transparent;
            border-radius: 0;
            vertical-align: middle;
            border: 2px solid $main-color-90;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            position: relative;
            cursor: pointer;
        }
        .checkbox-square.permission-red {
            margin-right: 5px;
            transition: 0;
            border-color: $error-normal;
        }
        .checkbox-square:checked {
            background-image: url("/assets/img/check-box.svg");
            background-position: -5px -4px;
        }
    }
}
.podkłady {
    font-family: $sofia;
    .collapse-auto {
        transition: .35s ease;
    }
    .path-next {
        font-weight: normal;
        font-size: 12px;
    }
    h2 {
        font-weight: 300;
        p {
            font-weight: normal;
        }
    }
    h3 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.18px;
        font-weight: bold;
    }
    .col-title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.18px;
        line-height: 24px;
        text-decoration: none;
        color: $main-color-90;
        img {
            display: none;
            position: absolute;
            width: 15px;
            height: 10px;
            right: 0;
            top: 78%;
            transform: translateY(-50%);
        }
    }
    .col-title[aria-expanded="true"] img {
        transform: translateY(-50%) rotate(180deg);
    }

    .filtr-title {
        font-family: $sofia;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        letter-spacing: 0;
    }
    .filtr-text {
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 16px;
    }
    button {
        background-color: transparent;
        border: none;
        img {
            margin-right: 5px;
            width: 12px;
            height: 8px;
        }
    }
    button[aria-expanded="true"] {
        img {
            transform: rotate(180deg);
        }
    }
    label {
        position: relative;

        .checkbox-round:checked {
            background-color: white;
            &::after {
                content: none;
            }
        }
        img {
            width: 50px;
            height: 50px;
        }
    }
    label.checked {
        p {
            color: $active-color-40;
        }
        .checkbox-round {
            background-color: white;
            border: 2px solid black;
            &::after {
                content: "";
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 2px solid #fff;
                position: absolute;
                background-color: black;
            }
        }
        .checkbox-round.orange {
            background-color: white;
            border: 2px solid $active-color-40;
            &::after {
                content: "";
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 2px solid #fff;
                position: absolute;
                background-color: $active-color-40;
            }
        }
    }
    label:hover {
        cursor: pointer;
    }
    .filtr-col {
        max-height: 800px;
        overflow: auto;
        p {
            border-bottom: none;
        }
        .inner-div {
            button {
                background-color:  transparent;
                border: none;
                img {
                    transform: rotate(0);
                    border: none;
                }
                p {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: -0.14px;
                    line-height: 20px;
                }
            }
        }
    }
    .filtr-col::-webkit-scrollbar {
        display: none;
    }
    .inner-div label {
        font-weight: 500;
        font-family: $sofia;
        letter-spacing: -0.12px;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
    }


    .product-box {
        .box-img {
            height: 23vw;
            max-height: 450px;
        }
    }
}
.about {
    .about-decora {
        .about-col {
            .path {
                font-family: sofia-pro;
                font-size: 12px;
                letter-spacing: -0.12px;
                line-height: 16px;
            }
            h1 {
                @include h2_Sofia_Pro;
            }
            h4 {
                font-size: 32px;
                line-height: 38px;
                font-family: sofia-pro;
                letter-spacing: -0.64px;
                font-weight: 300;
            }
            img {
                margin-top: 100px;
            }
            h2 {
                @include h5_Sofia_Pro;
            }
            .text-small {
                font-family: $plex;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
            }
        }
        .img-col{
            .box-img {
                overflow: hidden;
            }
            img{
                object-fit: cover;
            }
            img:hover {
                cursor: pointer;
                transform: scale(1.08);
            }
        }
    }
    .about-ewifoam {
        margin-top: 50px;
        margin-bottom: 50px;
        .about-col {
            h2 {
                @include h5_Sofia_Pro;
            }
            .text-small {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
                font-family: $plex;
            }
            .text-warum{
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.18px;
                font-family: $sofia;
                font-weight: bold;
            }
            .text-list {
                font-size: 14px;
                line-height: 22px;
                font-family: $sofia;
                letter-spacing: -0.14px;
                font-weight: 300;
            }
        }
        .img-col{
            .box-img {
                overflow: hidden;
            }
            img{
                object-fit: cover;
            }
            img:hover {
                transform: scale(1.08);
            }
        }
    }
}
.development {
    margin-top: 150px;
    .development-img {
        object-fit: cover;
        max-height: 600px;
    }
    .text-col {
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        background-color: $main-color-05;
        padding: 6%;

        max-width: 800px;
    }
}
.downloads {
    margin-bottom: 30px;
}
.downloads, .locations {
    margin-bottom: 30px;
    h1 {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -0.64px;
        line-height: 38px;
        font-family: $sofia;
    }
    .text-col {
        font-family: $plex;
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 18px;
        h2 {
            font-weight: bold;
        }
    }
    .hiden-cont {
        .file,
        .address {
            border-bottom: 2px solid $main-color-90;
            font-weight: bold;
            line-height: 20px;
            letter-spacing: 0;

            button {
                @include addBtn();
            }
            // button:focus-within {
            //     outline: 3px solid #cdcdcd;
            // }
            button[aria-expanded=true] img {
                transform: rotate(0);
            }
            p {
                left: 40px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 14px;
                letter-spacing: 0;
                text-align: left;
                line-height: 20px;
                font-family: $sofia;
                font-weight: bold;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
    .address-1 {
        background-color: #efefef;
        transition: 0.6s;
        h4 {
            font-family: $sofia;
            font-size: 32px;
            letter-spacing: -0.64px;
            line-height: 38px;
            font-weight: 300;
        }
        p {
            font-family: $sofia;
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 20px;
            font-weight: 500;
            a {
                color: #ffab40;
            }
        }
    }
    .catalogs,
    .guarantee {
        transition: 0.6s;
        background-color: #efefef;
        button {
            border: 0;
            font-weight: 500;
            font-size: 14px;
            font-family: $sofia;
            line-height: 20px;
            letter-spacing: -0.14px;
            img {
                object-fit: cover;
            }
        }
        a {
            color: black;
            font-weight: 500;
            font-size: 14px;
            font-family: $sofia;
            line-height: 20px;
            text-decoration: none;
        }
    }
}
.technology {
    h1 {
        font-size: 42px;
        letter-spacing: -1.05px;
        line-height: 50px;
        font-weight: bold;
        font-family: $sofia;
    }
    h2 {
        font-size: 32px;
        letter-spacing: -0.64px;
        font-family: $sofia;
        line-height: 38px;
        font-weight: 400;
    }
    p {
        font-family: $plex;
        font-size: 16px;
        letter-spacing: -0.64px;
        line-height: 24px;
    }
    h4 {
        font-family: $sofia;
        font-size: 32px;
        letter-spacing: -0.64px;
        line-height: 38px;
        font-weight: 300;
        strong {
            font-weight: bold;
        }
    }
}
.benefits {
    background-color: #f5f5f5;
    .benefits-name {
        border-bottom: 2px solid black;
    }

    .benefits-icon {
        border-radius: 50%;
        background-color: #fff;
        height: 134px;
        width: 134px;
        overflow: hidden;

        img {
            max-width: 70%;
            max-height: 90%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        img:hover {
            transform: scale(1.08);
        }
    }
    .benefits-text {
        h5 {
            font-family: $sofia;
            font-size: 24px;
            letter-spacing: -0.36px;
            line-height: 32px;
            font-weight: bold;
        }
        p {
            font-size: 12px;
            letter-spacing: -0.12px;
            line-height: 18px;
        }
    }
}
.icons {
    min-height: 350px;
    .ov {
        overflow: hidden;
        img {
            width: 150px;
            height: 150px;
        }
        img:hover {
            transform: scale(1.08);
        }
    }
}
.substrate {
    background-color: #f5f5f5;
    h4 {
        color: #51a66d;
        font-family: $sofia;
        font-size: 32px;
        letter-spacing: -0.64px;
        line-height: 38px;
        font-weight: 300;
        img {
            object-fit: scale-down;
        }
    }
    .im-circle-col {
        width: 100%;
        height: 100%;
        max-width: 289px;
        max-height: 265px;
        p {
            font-size: 12px;
            line-height: 16px;
            font-family: $sofia;
            font-weight: bold;
            color: $additional-color-60;
            letter-spacing: 0;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 10px);
            margin: 0;
            text-align: center;
        }
        .im-circle {
            border: 2px solid $additional-color-60;
            border-radius: 50%;
            overflow: hidden;
            background-color: transparent;
            padding: 10px;
            .box-img {
                border-radius: 50%;
            }
        }
    }
}
.parameter {
    .parameter-name {
        border-bottom: 2px solid black;
    }
    .parameter-icon {
        border-radius: 50%;
        background-color: #f5f5f5;
        height: 134px;
        width: 134px;

        img {
            object-fit: contain;
            width: 70%;
            height: 70%;
            // width: 70px;
            // height: 70px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        img:hover {
            transform: scale(1.08);
        }
    }

    .parameter-text {
        h5 {
            font-family: $sofia;
            font-size: 24px;
            letter-spacing: -0.36px;
            line-height: 32px;
            font-weight: bold;
        }
        p {
            font-size: 12px;
            letter-spacing: -0.12px;
            line-height: 18px;
        }
    }
}
.comparison {
    a {
        text-decoration: none;
        color: black
    }
    a:hover {
        cursor: pointer;
        color: black;
    }
}
.img-section {
    img {
        object-fit: cover;
        width: 100%;
        max-height: calc(100vh - 100px);
    }
}
footer {
    padding: 0 0 50px 0;
    color: $main-color-90;
    font-family: $plex;
    width: 100%;
    .footer-logo {
        display: flex;
        justify-content: center;
        padding: 0;
        a {
            margin: auto;
            width: 60%;
            display: flex;
            align-content: flex-start;
        }
    }
    .search-cont {
        margin-right: 5;
        margin-left: auto;
    }
    form {
        height: 40px;
        padding: 0 5px;
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        @include input;
        label {
            border-radius: 50px;
            border: 2px solid $main-color-90;
            overflow: hidden;
            height: 40px;
            width: 50%;
            max-width: 300px;
            // button:focus {
            //     outline: 3px solid #CDCDCD;
            // }
        }
        input {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.14px;
            width: calc(100% - 50px);
            background-color: transparent;
        }
        label:hover, label:focus-within, label:focus {
            width: 70%;
        }
        img {
            object-fit: contain;
            height: 24px;
            width: 24px;
        }
    }
    .row-border {
        width: 100%;
        border: 2px solid $main-color-90;
        border-left: none;
        border-right: none;
        display: flex;
        flex-wrap: nowrap;
        flex-grow: 1;
    }
    .footer-nav {
        padding: 60px 0 50px;
        display: flex;
        flex-wrap: nowrap;
        .footer-col {
            padding: 0 20px 0 0;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            h5 {
                @include h5_Sofia_Pro;
            }
            a {
                font-family: $sofia;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                color: $main-color-90;
                text-decoration: none;
                text-transform: uppercase;
                width: 100%;
            }
            a:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            p {
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 22px;
                a {
                    font-family: $plex;
                    font-weight: normal;
                    text-transform: none;
                }
            }
            .footer-icons {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                a {
                    text-decoration: none;
                    margin: 0;
                    img {
                        margin-right: 10px;
                    }
                    img:hover {
                        transform: scale(1.08);
                        cursor: pointer;
                    }
                    // img:focus {
                    //     outline: 3px solid #cdcdcd;
                    // }
                }
            }
            .special-padding {
                padding-top: 1rem !important;
            }
        }
    }
    .footer-information {
        border-left: 2px solid $main-color-90;
        padding: 90px 61px 50px 61px;
        position: relative;
        p {
            @include T4_IBM_Plex;
        }
        a {
            @include T4_IBM_Plex;
            text-decoration: none;
            color: black;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
.footer-newsletter {
    padding: 0 0 50px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .newsletter-form {
        padding: 0 20px 0 0;
    }
    h4 {
        font-family: $sofia;
        line-height: 38px;
        font-weight: bold;
        letter-spacing: -0.64px;
        font-size: 32px;
        margin-bottom: 15px;
    }
    p {
        font-size: 16px;
        letter-spacing: -0.16px;
        line-height: 24px;
        font-family: $plex;
        margin: 0;
    }
    form {
        height: 40px;
        padding: 0 5px;
        @include btnWithoutImg($main-color-90, $active-color-40, $active-color-60);
        @include input;
        label {
            border-radius: 50px;
            border: 2px solid $main-color-90;
            overflow: hidden;
            height: 40px;
            width: 50%;
            max-width: 300px;
            // button:focus {
            //     outline: 3px solid #CDCDCD;
            // }
        }
        input {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.14px;
            width: calc(100% - 50px);
            background-color: transparent;
        }
        label:hover, label:focus-within, label:focus {
            width: 70%;
        }
        img {
            object-fit: contain;
            height: 24px;
            width: 24px;
        }
    }
}

.rotateVertical {
    transform: rotate(180deg) !important;
}

.footer-bg {
    background-color: $main-color-05;
}
.path-section {
    .path {
        font-family: $sofia;
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 16px;
        font-weight: 500;
        p {
            font-family: $plex;
            font-size: 16px;
            letter-spacing: -0.64px;
            line-height: 24px;
        }
        a {
            font-family: $sofia;
            font-size: 12px;
            color: $main-color-90;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
            color: $main-color-90;
        }

    }
}

.path {
    font-family: $sofia;
    font-size: 12px;
    padding-top: 40px;
    letter-spacing: -0.12px;
    line-height: 16px;
    font-weight: 500;
    a {
        font-family: $sofia;
        font-size: 12px;
        color: $main-color-90;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
        color: $main-color-90;
    }
}

.padding-top {
    padding-top: 30px !important;
}
.communicat {
    @include btnWithoutImg($main-color-00, $main-color-90, $main-color-80);
    bottom: 10%;
    left: 10%;
    z-index: 3;
    border-radius: 20px;
    width: 378px;
    height: auto;
    p,
    a {
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 18px;
    }
    a {
        color: black;
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
    button {
        transform: translate(0, 0);
        color: white;
    }
    // button:focus {
    //     outline: 3px solid #cdcdcd;
    // }
}
.vertical-imgs {
    z-index: 3;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    max-width: 100px;
    flex-direction: column;
    img {
        &:hover {
            transform: scale(1.08);
        }
    }
}
.checkbox-round {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid $main-color-90;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    cursor: pointer;
}
.checkbox-round:checked {
    background-color: $main-color-90
}
.checkbox-round:checked::after {
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: black;
    border: 2px solid $main-color-00;
    position: absolute;
}
.checkbox-square {
    width: 18px;
    height: 18px;
    padding: 0;
    transition: 0;
    background-color: transparent;
    border-radius: 0;
    vertical-align: middle;
    border: 2px solid $main-color-90;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    cursor: pointer;
}
.checkbox-square.permission-red {
    margin-right: 5px;
    border-color: $error-normal;
}
.checkbox-square:checked {
    background-image: url("/assets/img/check-box.svg");
    background-position: -5px -4px;
}
.product-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .box-img {
        margin-bottom: 10px;
        height: 310px;
        overflow: hidden;
        display: block;
        a {
            display: block;
            width: 100%;
            height: 100%;
            text-decoration: none;
        }
        button {
            background-color: transparent;
            border: none;
            height: 50px;
            width: 50px;
            top: 10px;
            right: 10px;
            z-index: 1;

            img:hover {
                transform: scale(1);
            }
        }
        button:hover {
            cursor: pointer;
        }
        // button:focus {
        //     outline: 3px solid #CDCDCD;;
        // }
        .back-img {
            transition: 0.6s;
            height: 100%;
            opacity: 0;
        }
        .front-img {
            transition: 0.6s;
            height: 100%;
        }
        img {
            transition: 0.6s;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }
        img:hover {
            transform: scale(1);
        }
    }
    .box-img:hover {
        cursor: pointer;
        .back-img {
            opacity: 1;
        }
    }
    .icon-row {
        img {
            height: 45px;
            margin-right: 4px;
        }
    }
    .slide-content {
        position: relative;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        a {
            width: 100%;
            text-decoration: none;
            color: black;
            @include h7_Sofia_Pro;
        }
        p {
            font-size: 14px;
            letter-spacing: -0.14px;
            line-height: 20px;
            font-weight: 500;
            font-family: $sofia;
            width: 100%;
            a {
                font-size: 14px;
                letter-spacing: -0.14px;
                line-height: 20px;
                font-weight: 500;
                font-family: $sofia;
                width: 100%;
            }
        }

        a:hover {
            cursor: pointer;
        }
        .product-describe {
            font-family: $plex;
            font-size: 12px;
            margin-top: auto;
            letter-spacing: -0.12px;
            line-height: 18px;
            span {
                font-weight: bold;
                &:hover {
                    cursor: default;
                }
            }
            &:hover {
                cursor: default;
            }
        }
        .icon-row {
            width: 100%;
        }
    }
    .slide-content::after {
        content: "";
        transition: 0.6s;
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 2px solid $main-color-90;
    }
    .slide-content::before {
        content: "";
        transition: 0.5s 0.2s;
        width: 0%;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 2px solid $main-color-90;
    }
}
.product-box:hover {
    .luminosity {
        filter: grayscale(100%);
    }
    .slide-content::after {
        width: 0;
        left: 100%;
    }
    .slide-content::before {
        width: 100%;
        left: 0;
    }
}
.box-img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    img:hover {
        transform: scale(1.08);
    }
}
.btn-cont-x2 {
    z-index: 3;
    display: flex;
    position: absolute;
    height: 40px;
    width: auto;
    align-items: center;
    p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        font-family: $sofia;
    }
    button {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 5px;
        border: none;
        background-color: transparent;
    }
    // button:focus {
    //     outline: 3px solid #cdcdcd !important;
    // }
}
.bord-t {
    border-top: 2px solid $main-color-90;
}
.bord-b {
    border-bottom: 2px solid $main-color-90;
}
.disabled {
    opacity: 0.3 !important;
    outline: 0 !important;
}
.disabled:hover {
    opacity: 0.1;
    cursor: default;
    transform: scale(1);
    filter: invert(0%) !important;
}
.advices {
    margin-top: 85px;
    p {
        font-size: 14px;
        letter-spacing: -0.14px;
        line-height: 22px;
        font-family: $plex;
    }
}
.max-width {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}
.max-width-v2{
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes arrowMove {
    0% {
        bottom: 40px;
    }
    5% {
        bottom: 30px;
    }
    10% {
        bottom: 40px;
    }
}
@import "slick";
@import "responsive"; // --- Vendors Bootstrap 4