// @media (min-width: 576px){

// }
// @media (min-width: 1200px){
    
// }
// @media (min-width: 1470px) {

// }
@media (max-width: 1469px) {
    .scroll-container .carousel-container {
        .slick-dots {
            right: 330px;
            bottom: 28px;
        }
        .slick-item .text-carousel {
            padding-left: 0 !important;
            padding-right: 10px !important;
            padding-top: 30px !important;
            padding-bottom: 30px !important;
            width: 505px;
            h1, h2 {
                font-size: 38px;
            }
            h4 {
                font-size: 28px;
                padding-bottom: 20px !important;
            }
        }
    }

    .main-product {
        .box {
            height: 550px;
        }
        .img-row {
            height: 300px;
        }
    }
    .why-ewifoam {
        max-height: 100%;
        .text-col {
            padding: 2%;
        }
        .icons-row {
            img {
                height: 80px;
            }
        }
    }
    .about-product {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 1320px !important;
    }
    .contact {
        h1, h2, h3 {
            font-size: 28px;
        }
        .contact-data a {
            font-size: 28px;
        }
    }
    .podkłady h2 {
        font-size: 28px;
    }
    .answer {
        background-size: 54%;
    }
    .expert .box-img {
        height: 350px;
    }
    .substrate .im-circle-col {
        max-width: 247px;
        max-height: 223px;
    }
}
@media (max-width: 1399px) {
    .podkłady .product-box .box-img { 
        height: calc(35vw - 6px);
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width:1199px) {  
    //h4 font-size: 28px;  line-height: 36px;  letter-spacing: -0.48px;
    .podkłady .product-box .box-img { 
        height: calc(35vw - 12px);
    }
    .scroll-container {
        .carousel-container {
            .slick-dots {
                right: 235px;
                bottom: 18px;
            }
            .slick-item {
                .text-carousel {
                    width: 400px;
                    padding-bottom: 20px !important;
                    h1, h2 {
                        font-size: 32px;
                        line-height: 38px;
                    }
                    h4 {
                        font-size: 24px;
                        line-height: 32px;
                        padding-bottom: 10px !important;
                    }
                }
            }
        }
    }
    .main-product {
        .box {
            height: 450px;
        }
        .img-row {
            height: 300px;
        }
    }
    .vertical-imgs {
        max-width: 80px;
    }
    .product-details {
        padding-bottom: 0px;
        .details-col {
            margin-bottom: 20px !important;
        }
        .img-col {
            padding-left: 24px !important;
        }
    }
    .answer {
        h4, p {
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -0.48px;
        }
        button h4 {
            padding-left: 20px;
        }
    }
    .expert {
        .exp-col {
            margin-bottom: 20px;
        }
        .box-img {
            height: 420px;
        }
    }
    footer  {
        .footer-nav {
            padding: 50px 0 40px;
            .footer-col {
                p, a {
                    font-size: 12px;
                }
                h5 {
                    font-size: 22px;
                }
            }
        }
        .footer-information {
            padding: 70px 41px 40px 41px;
        }
    }
    .footer-newsletter {
        .newsletter-form {
            padding: 0 10px 0 0;
        }
        h4 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
        form {
            margin: 40px 0 0 0;
            label {
                input {
                    font-size: 12px;
                }
            }
        }
    }
    .substrate .im-circle-col {
        width: 203px;
        height: 179px;
    }
}
/* Large devices (desktops, 992px and up) */
@media (max-width: 991px) { 
    .podkłady .product-box .box-img { 
        height: 45vw;
    }
    .scroll-container {
        .carousel-container {
            .slick-item {
                .text-carousel {
                    max-width: 80%;
                    width: 535px;
                    left: 50%;
                    transform: translateX(-50%);
                    h1, h2 {
                        font-size: 32px;
                        line-height: 38px;
                    }
                    h4 {
                        font-size: 24px;
                        line-height: 32px;
                    }
                    .slick-dots {
                        left: auto; 
                        right: 20px;
                    }
                }
            }
            .sign {
                display: none;
            }
            .slick-dots {
                right: 40%;
            }
        }
    }

    .main-product {
        .main-col {
            padding: 8px !important;
        }
        .box {
            height: 350px;
        }
        .box-text {
            width: 330px;
            p {
                font-size: 16px;
            }
        }
        .img-row {
            height: 300px;
        }
        .box-img {
            h2 {
                font-size: 16px;
            }
        }
    }
    .why-ewifoam {
        margin-top: 50px;
        .text-col {
            padding: 20px;
        }
        .box-img {
            max-height: 415px;
        }
    }
    .answer {
        flex-wrap: wrap;
    }
    .contact {
        padding-bottom: 0;
        h3 {
            font-size: 24px;
            line-height: 30px;
        }
        .contact-data a {
            font-size: 24px;
            line-height: 30px;
        }
    }
    .podkłady h2 {
        font-size: 28px;
    }
    .product-details {
        .details-col {
            padding-right: 24px !important;
            padding-left: 24px !important;
            .resp-none { 
                display: none;
            }
        }
        .resp-d {
            display: block;
        }
        .img-col {
            padding-right: 24px !important;
            height: 50vh;
        }
    }
    .about-product {
        .about-col {
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
            h3 {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
    .podkłady {
        .col-title img {
            display: block;
        }
        h2 {
            font-size: 24px;
        }
    }
    .about {
        .about-decora .about-col img {
            margin-top: 50px;
        }
        .about-ewifoam {
            margin-top: 50px;
        }
    }
    .expert-articles .btn-cont-x2  {
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: 10px;
    }
    .expert .box-img {
        height: 330px;
    }
    .advices {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    footer  {
        .footer-nav {
            padding: 50px 0 40px;
            .footer-col {
                padding: 0 10px 0 0;
                p, a {
                    font-size: 12px;
                }
                h5 {
                    font-size: 20px;
                }
                .footer-icons {
                    width: 100%;
                    justify-content: left;
                    a {
                        width: 44px;
                    }
                    img {
                        width: 34px;
                        height: 34px;
                    }
                }
            }
        }
        .footer-information {
            padding-left: 0;
            padding-right: 0;
            border-left: none;
        }
    }
    .row-padding {
        margin: 0 15px;
    }
    .footer-newsletter {
        h4 {
            font-size: 24px;
        }
        p {
            font-size: 12px;
        }
        form {
            margin: 30px 0 0 0;
            label:hover, label:focus-within, label:focus {
                width: 60%;
            }
        }
    }
    .substrate .im-circle-col {
        width: 157px;
        height: 133px;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 767px) {  
    .podkłady .product-box .box-img { 
        height: calc(44vw - 6px);
    }
    header {
        form {
            width: auto !important;
            margin-left: 0;
        }
    }
    .scroll-container {
        height: 75vh;
        .carousel-container {
            height: 75vh;
    
            .slick-track {
                height: 50vh;
            }
            .slick-item { 
                height: 50vh;
                .text-carousel {
                    border-radius: 0;
                    width: 100%;
                    bottom: calc(0px - 25vh);
                    max-width: 100%;
                    min-height: 25vh;
                    left: 0;
                    transform: translateX(0);
                    padding-top: 20px !important;
                    padding-bottom: 20px !important;
                    .slider {
                        max-width: 100%;
                        h1, h2 {
                            font-size: 28px;
                            line-height: 32px;
                            padding-bottom: 10px !important;
                        }
                        h4 {
                            font-size: 20px;
                            line-height: 28px;
                            padding-bottom: 20px !important;
                        }
                    }
                    button {
                        margin-top: 10px;
                    }
                }
                img {
                    height: 50vh;
                    min-height: 1px;
                }
            }
            .sign {
                display: none;
            }
            .slick-dots {
                right: 20%;
                bottom: 27px;
            }
        }
    }
    .main-product .main-col {
        padding: 8px 0 !important;
        .box {
            height: 450px;
        }
        .box-text {
            padding: 12px !important;
            height: 100px;
            width: 400px;
            left: -65px;
        }
    }
    .expert-articles {
        h2 {
            font-size: 24px;
            line-height: 30px;
        }
        .cont-row {
            margin-right: 0 !important;

        }
    }
    // .product-details {
    //     padding-bottom: 0px;
    //     .details-col {
    //         margin-bottom: 20px;
    //         .resp-none { 
    //             display: none;
    //         }
    //     }
    //     .resp-d {
    //         display: block;
    //     }
    //     .img-col {
    //         padding-right: 24px !important;
    //         padding-left: 24px !important;
    //         height: 50vh;
    //     }
    // }
    .vertical-imgs {
        max-width: 60px;
    }
    .about-product {
        padding-bottom: 0;
        padding-top: 0;
        .about-col .describe {
            padding: 0!important; 
        }
        .about-img {
            margin-top: 0;
        }
    }
    .answer {
        min-height: 350px;
    }
    .expert {
        padding-bottom: 20px;
        .exp-col {
            margin-bottom: 20px !important;
        }
        .box-img {
            height: 250px;
        }
        .text-box {
            padding-right: 0 !important;
        }
        .advices {
            margin-top: 0 !important;
            margin-bottom: 20px!important;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }
    .contact {
        .title {
            border: none;
        }
        .exp-info {
            border: none;
            p {
                border-bottom: 2px solid $main-color-90;
            }
        }
    }
    .podkłady {
        label img {
            height: 40px;
            width: 40px;
        }
        .icon-row img {
            height: 40px;
        }
        .exp-info p {
                border: none;
        }
        .advices {
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
        }
    }
    .substrate {
        .im-circle-col {
            width: 117px;
            height: 93px;
            .im-circle  {
                padding: 5px;
            }
        }
        .im-circle-col-x2 {
            margin: 20px 0;
        }
    }
    footer  {
        .footer-nav {
            padding: 40px 0 30px;
        }
        .footer-information {
            padding: 30px 0 40px 0;
        }
    }
    .footer-bg {
        .footer-logo {
            justify-content: flex-start;
            margin-bottom: 40px;
            a {
                margin-left: 0;
            }
        }
        
    }
    .footer-newsletter {
        padding: 0 0 40px 0;
        flex-wrap: wrap;
        h4 {
            margin-bottom: 10px;
        }
        form {
            justify-content: flex-start;
            label:hover, label:focus-within, label:focus {
                width: 50%;
            }
        }
    }
    .resp-mb {
        margin-bottom: 30px;
    }
    .img-section img {
        max-height: 50vh;
    }
    .row-product .slider {
        max-height: 469px;
    }
}
/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {  
    //h4 font-size 20px
    .podkłady .product-box .box-img { 
        height: calc(100vw - 40px);
        max-height: 529px;
    }
    header {

        .nav-links.active {
            top: 143px;
        }
        nav {
            margin-left: 12px;
            margin-right: 12px;
            form input {
                width: 100%; 
            }
            .nav-logo {
                margin-left: 0;
            }
            .nav-buttons {
                margin-right: 0;
                margin-left: auto;
            }
        }
        .search-cont {
            position: absolute;
            top: 24px;
            left: -45px;
            background-color: $main-color-00;
            width: calc(100% + 56px);
            height: auto;
            padding: 5px 28px;
            padding-top: 10px;
            z-index: 0;
            form {
                position: static;
            }
        }
        .search-cont.active {
            top: 92px;
            form:focus-within, form:hover {
                margin-left: 38px;
            }
        }
    }
    .scroll-container {
        .carousel-container {
            .slick-item { 
                .text-carousel {
                    max-width: 100%;
                    padding-top: 0px !important;
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                    padding-bottom: 0px !important;
                    .slider {
                        margin-bottom: 10px;
                        width: 100%;
                        h1, h2 {
                            font-size: 24px;
                            line-height: 28px;
                            padding-bottom: 0 !important;
                        }
                        h4 {
                            padding-bottom: 10px !important;
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
            }
            .slick-dots {
                bottom: 9px
            }
        }
    }
    .main-product {
        padding-left: 12px !important;
        padding-right: 12px !important;
        h2 {
            margin: 80px 0;
        }
        .main-col {
            .img-row {
                height: 300px;
            }
            .box {
                height: 300px;
            }
            .box-text {
                left: 0;
                height: auto !important;
                width:  250px;
                transform: translateX(-25%)
            }
            .box-img  {
                width: 300px;
            }
        }   
    }
    .row-product {
        padding-left: 12px !important;
        padding-right: 12px !important;
        h2 {
            margin: 80px 0;
        }
        .rss-next {
            right: 10px;
        }
        .rss-prev {
            left: 10px;
        }
    }
    .why-ewifoam {
        .text-col {
            h3 {
                font-size: 24px;
            }
            p, ul {
                font-size: 12px;
                line-height: 18px;
            }
            span {
                font-size: 14px;
            }
        }
        .icons-row {
            justify-content: center;
        }
    }
    .answer {
        padding-left: 12px !important;
        padding-right: 12px !important;
        background-size: 62%;
        h4, p {
            font-size: 20px;
        }
        .btn-cont {
            height: 60px;
            span {
                width: 60px;
            }
            img {
                margin-right: 23px;
            }
        }
    }
    .expert {
        padding-bottom: 0;
        padding-left: 25px !important;
        padding-right: 25px !important;
        .exp-col {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .text-box {
            padding-right: 0 !important;
        }
        .advices {
            margin-top: 0 !important;
            margin-bottom: 20px!important;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .advices {
            margin-top: 50px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            h4 {
                font-size: 20px;
                line-height: 24px;
            }
            p {
                font-size: 12px;
                line-height: 18px;
            }
        }
        h2 button {
            top: 67px;
        }
        .box-img {
            height: 330px;
        }
    }
    .expert-page {
        padding: 0 25px !important;
        p, .title, .exp-info {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .exp-col {
            padding: 10px 0px !important;
            margin-bottom: 10px;
        }
    }
    .expert-articles p {
        margin-right: 0 !important;
    }
    .podkłady {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .contact {
        h2, h3 {
            font-size: 24px;
            line-height: 30px;
        }
        .contact-data {
            a {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
    .locations .advices {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .product-details {
        .details-col {
            padding-left: 12px !important;
            padding-right: 12px !important;
            p {
                font-size: 12px;
            }
            .hiden-cont .to-download .to-download-cont button p {
                font-size: 12px;
            }
        }
        .img-col {
            padding-left: 12px !important;
            padding-right: 12px !important;
            .bs {
                min-height: 350px;
                .next {
                    right: 50px;
                }
                .prev {
                    left: 50px;
                }
            }
        }
    }
    .vertical-imgs {
        // max-width: 70px;
        display: none;
    }
    .communicat {
        left: 5%;
        bottom: 5%;
        width: 340px;
    }
    .about-product {
        .about-col {
            padding-right: 0 !important;
            padding-left: 0!important;
            margin-left: 12px !important;
            margin-right: 0 !important;
        }
    }
    .parameter {        
        .parameter-box{
            justify-content: center!important;
            .parameter-text{
                text-align: center;
            }
        }
    }
    .benefits {
        .benefits-box{
            justify-content: center!important;
            .benefits-text{
                text-align: center;
            }
        }
    }
    .substrate {
        .im-circle-col {
            height: 100px;
            max-width: none;
            width: 100%;
            .im-circle {
                width: 100px;
                height: 100px;
            }
            .pluse:hover {
                transform: scale(1);
            }
        }
    }
    footer  {
        .footer-nav {
            padding: 20px 0 20px;
            flex-wrap: wrap;
            .footer-col {
                padding: 0 10px 0 0;
                p, a {
                    width: 100%;
                }
                h5 {
                    font-size: 18px;
                    margin: 10px 0 0;
                }
                .footer-icons img {
                    width: 30px;
                    height: 30px;
                }
                .special-padding {
                    padding-top: 0 !important;
                }
            }
        }
        .footer-information {
            padding: 20px 10px 20px 0;
            border-left: none;
        }
    }
    .footer-bg {
        .footer-logo {
            margin-bottom: 30px;
            a {
                width: 100%;
            }
        }
    }
    .row-padding {
        margin: 0;
    }
    .footer-newsletter {
        padding-bottom: 30px;
        form {
            flex-wrap: wrap;
            margin-top: 20px;
            label {
                margin-bottom: 10px;
            }
            label:hover, label:focus-within, label:focus {
                width: 50%;
            }
        }
    }
}
@media (max-width: 375px) {  
    //h2 font-size 28px line-height:32px
    header {
        nav {
            .search-cont {
                position: absolute;
                top: -100%;
                left: -12px;
                background-color: $main-color-00;
                transform: translateY(-100%);
                padding: 5px 12px 5px 12px ;
                width: 100%;
                z-index: 1;
                form {
                    width: calc(100vw - 130px) !important;
                    
                }
            }
            .search-cont.active {
                top: 108%;
                transform: translateY(0);
            }
            // .nav-buttons {
            //     position: absolute;
            //     top: -100%;
            //     right: -12px;
            //     background-color: $main-color-00;
            //     transform: translateY(-100%);
            //     padding: 5px 12px 5px 0;
            //     width: 100px;
            //     z-index: 1;
            // }
            // .nav-buttons.active {
            //     top: 108%;
            //     transform: translateY(0);
            // }
            .nav-logo {
                margin-left: 0;
            }
        }
    //     .nav-links.active {
    //        top: 230%;
    //    }
    }
    .main-product h2 {
        font-size: 28px;
        line-height: 32px;
        margin: 50px 0;
    }
    .row-product h2 {
        margin: 50px 0;
        font-size: 28px;
        line-height: 32px;
    }
    .answer {
        height: 350px;
        .btn-cont {
            height: 50px;
            span {
                width: 46px;
            }
            img {
                margin-right: 15px;
            }
        }
        h4, p {
            font-size: 18px;
        }
    }
    .expert {
        h2 {
            margin-top: 50px;
            font-size: 28px;
            line-height: 32px;
        }
        .box-img {
            max-height: 300px;
        }
        .exp-col.text-box {
            padding-right: 0 !important;
        }
    }
    .expert-articles {
        h1 {
            font-size: 32px;
            line-height: 40px;
        }
        h2 {
            font-size: 28px;
            line-height: 36px;
        }
    }
    .contact {
        h2, h3 {
            font-size: 20px;
            line-height: 28px;
        }
        .contact-data {
            a {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
    .product-details .details-col h2 {
        font-size: 32px;
        line-height: 40px;
    }
    footer {
        padding: 30px 0;
        .footer-nav {
            .footer-col {
                padding: 0;
            }
        }
    }
    .footer-newsletter {
        .newsletter-form {
            padding: 0;
        }
        h4 {
            font-size: 22px;
            line-height: 30px;
        }
        form {
            label {
                margin-right: 0;
                width: 60%;
            }
            label:hover, label:focus-within, label:focus {
                width: 50%;
            }
            button a {
                font-size: 12px;
            }
        }
    }
    .communicat {
        left: 0%;
        bottom: 0%;
        width: 100vw;
    }
}